import {Season} from "../types/types";

// @ts-ignore
import img_season116 from "./images/seasonLabels/img_season1.16.png";
// @ts-ignore
import img_season117 from "./images/seasonLabels/img_season1.17.png";
// @ts-ignore
import img_season118 from "./images/seasonLabels/img_season1.18.png";
// @ts-ignore
import img_season119 from "./images/seasonLabels/img_season1.19.png";
// @ts-ignore
import img_season120 from "./images/seasonLabels/img_season1.20.png";
// @ts-ignore
import img_season121 from "./images/seasonLabels/img_season1.21.png";
// @ts-ignore
import img_season1214 from "./images/seasonLabels/img_season1.21.4.png";
// @ts-ignore
import img_hub from "./images/img_hub.png";
// @ts-ignore
import img_experimental from "./images/img_experimental.png";

export const season116: Season = {
    name: "Season 1.16",
    ip: "45.81.233.139:25562",
    defaultStatus: "Offline",
    isShattered: false,
    imageLabelUrl: img_season116,
    blueMapLink: "https://season116.eternitycraft.de",
    downloadMapUrl: "maps/map_1_16.zip",
    color1: "#43fc4b",
    color2: "#5cf17c"
}

export const season117: Season = {
    name: "Season 1.17",
    ip: "45.81.233.139:25563",
    defaultStatus: "Offline",
    isShattered: false,
    imageLabelUrl: img_season117,
    blueMapLink: "https://season117.eternitycraft.de",
    downloadMapUrl: "maps/map_1_17.zip",
    color1: "#f81f19",
    color2: "#c8862c"
}

export const season118: Season = {
    name: "Season 1.18",
    ip: "45.81.233.139:25564",
    defaultStatus: "Offline",
    isShattered: false,
    imageLabelUrl: img_season118,
    blueMapLink: "https://season118.eternitycraft.de",
    downloadMapUrl: "maps/map_1_18.zip",
    color1: "#a10302",
    color2: "#141414"
}

export const season119: Season = {
    name: "Season 1.19",
    ip: "45.81.233.139:25565",
    defaultStatus: "Offline",
    isShattered: false,
    imageLabelUrl: img_season119,
    blueMapLink: "https://season119.eternitycraft.de",
    downloadMapUrl: "maps/map_1_19.zip",
    color1: "#3ce9ea",
    color2: "#3a5352"
}

export const season120: Season = {
    name: "Season 1.20",
    ip: "",
    defaultStatus: "Shattered",
    isShattered: true,
    imageLabelUrl: img_season120,
    blueMapLink: null,
    downloadMapUrl: null,
    color1: "#323130",
    color2: "#595653",

}

export const season121: Season = {
    name: "Season 1.21",
    ip: "45.81.233.139:25567",
    defaultStatus: "Offline",
    imageLabelUrl: img_season121,
    isShattered: false,
    blueMapLink: "https://season121.eternitycraft.de",
    downloadMapUrl: "maps/map_1_21.zip",
    color1: "#0e01bb",
    color2: "#620193"
}

export const season1214: Season = {
    name: "Season 1.21.4",
    ip: "45.81.233.139:25568", // TODO: Check if the ip is right
    defaultStatus: "Offline",
    imageLabelUrl: img_season1214,
    isShattered: false,
    blueMapLink: "",
    downloadMapUrl: null,
    color1: "#e1ac34",
    color2: "#f7c40d"
}

export const hub: Season = {
    name: "Hub",
    ip: "45.81.233.139:25561",
    defaultStatus: "Offline",
    imageLabelUrl: img_hub,
    isShattered: false,
    blueMapLink: null,
    downloadMapUrl: null,
    color1: "#d2d2d2",
    color2: "#5f5e5f"
}

export const experimental: Season = {
    name: "Experimental",
    ip: "45.81.233.139:25566",
    defaultStatus: "Offline",
    imageLabelUrl: img_experimental,
    isShattered: false,
    blueMapLink: null,
    downloadMapUrl: null,
    color1: "#f03ef1",
    color2: "#664368",
}

export const seasons: Season[] = [
    season116,
    season117,
    season118,
    season119,
    season120,
    season121,
    hub,
    experimental,
]



