import styled from "styled-components";
import {buttonHoverColor, mainBackgroundColor, secondaryTextColor} from "../../variables.styles";

export const SignUpContainer = styled.div`
    background-color: ${mainBackgroundColor};
    display: flex;
    justify-content: start;
    padding: 10px;
    padding-right: 24px;
    width: auto;
    margin-right: -20px;
    transform: translateY(0);
    transition: transform 0.5s ease, color 0.4s ease;

    &:hover {
        transform: translateX(-10px);
        cursor: pointer;

        h2 {
            color: ${buttonHoverColor}
        }
    }
    
    @media (max-width: 1200px) {
        width: 40px;
    }

`;

export const SignUpText = styled.h2`
    margin: 0;
    color: ${secondaryTextColor};
`;