import {SignUpContainer, SignUpText} from "./SignUp.styles";
import {Link} from "../mainPage/MainPage.styles";
import {useTranslation} from "react-i18next";

const SignUp = () => {

    const { t } = useTranslation();

    return(
        <SignUpContainer>
            <Link href={"/signUp"}>
                <SignUpText>{window.innerWidth < 1200 ? "→" : `${t('signUp')} →`}</SignUpText>
            </Link>
        </SignUpContainer>
    )

}

export default SignUp;