import styled from "styled-components";
import {buttonColor, buttonHoverColor, mainBackgroundColor} from "../../variables.styles";
import {SeasonCardWrapperType} from "../../types/types";
import cracks from "../../data/images/cracks.png";

export const SeasonCardWrapper = styled.div<SeasonCardWrapperType>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 10px;
    margin: 30px;
    transition: background 0.5s ease-in-out, transform 0.5s ease, box-shadow 0.5s ease;
    transform: translateY(0);
    
    &:hover {
        transform: translateY(-10px);
        background: linear-gradient(to bottom, ${props => props.color1}, ${props => props.color2});
        box-shadow: 0 0 10px 0 ${props => props.color1};
    }
`;


export const ShatteredSeasonCardWrapper = styled.div<SeasonCardWrapperType>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px;
    border-radius: 10px;
    margin: 30px;
    cursor: help;
    transition: background 0.5s ease, transform 0.5s ease, box-shadow 0.5s ease;

    &:hover {
        transform: rotate(3deg) translateY(16px);
        background: linear-gradient(to bottom, ${props => props.color1}, ${props => props.color2});
        box-shadow: 0 0 10px 0 ${props => props.color1};
    }

    &:not(:hover) {
        transform: translateY(0);
        filter: none;
    }
`;

export const SeasonCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: ${mainBackgroundColor};
    border-radius: 10px;
`;

export const ShatteredSeasonCardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    background-color: ${mainBackgroundColor};
    background-image: url(${cracks});
    background-size: cover;
    background-position: center;
    border-radius: 10px;
    filter: blur(0.4px);
`;

export const SeasonCardImageContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

export const SeasonCardImage = styled.img`
    width: 100%;
    height: 100%;
    border-radius: 10px;
`;

export const SeasonCardContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-width: 260px;
    width: 70%;
`;

export const SeasonCardInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 130px;
`;

export const StatusInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
`;

export const Status = styled.span`
    cursor: default;
`;

export const PlayerText = styled.p`
    cursor: default;
`;

export const SeasonCardLinks = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid grey;
    min-width: 130px;
`;

export const Button = styled.button`
    margin: 5px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 41px;
    background-color: ${buttonColor};
    font-family: 'Minecraft', sans-serif;

    &:hover {
        background-color: ${buttonHoverColor};
        font-weight: bold;
        transition: background-color 0.4s ease, font-weight 0.1s ease;
    }
`;

export const ButtonOffline = styled.button`
    margin: 5px;
    cursor: default;
    border: none;
    border-radius: 5px;
    width: 100px;
    height: 41px;
    background-color: ${buttonColor};
    color: grey;
    font-family: 'Minecraft', sans-serif;
`;