import {Routes, Route} from 'react-router-dom'
import MainPage from "./components/mainPage/MainPage.component";
import SignUpTable from "./components/signUpTable/SignUpTable.component";

const App = () => {

  return (
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/signUp" element={
         <SignUpTable/>
        }></Route>
      </Routes>
  )

}

export default App;
