import styled from "styled-components";

export const MainPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

export const EternityLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
    margin-bottom: 100px;

    @media (max-width: 675px) {
        margin-bottom: 50px;
    }
`;

export const EternityLogo = styled.img`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: 35%;
    min-width: 300px;
    min-height: 75px;
    
`;

export const SeasonCardContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    width: 70vw;
`;

export const OuterSeasonCard = styled.div`
    @media (max-width: 375px) {
        scale: 90%;
    }
`;

export const SeasonCardSpacer = styled.div`
    margin: 10px;
    width: 65vw;
    border-bottom: 4px solid rgba(36, 37, 38, 0.29);
`;

export const Link = styled.a`
    text-decoration: none;
    color: inherit;
`;

export const OuterSignUpContainer = styled.div`
    position: fixed;
    display: flex;
    right: 0;
    top: 130px;
    z-index: 100;
`;
