import {FC, useEffect, useState} from "react";
import {SeasonCardProps} from "../../types/types";
import {
    Button,
    ButtonOffline, PlayerText,
    SeasonCardContainer,
    SeasonCardContent, SeasonCardImage, SeasonCardImageContainer,
    SeasonCardInfo,
    SeasonCardLinks,
    SeasonCardWrapper, ShatteredSeasonCardContainer, ShatteredSeasonCardWrapper, Status, StatusInfo
} from "./SeasonCard.styles";
import {Link} from "../mainPage/MainPage.styles";
import {useTranslation} from "react-i18next";


const SeasonCard: FC<SeasonCardProps> = ({season}) => {

    const [status, setStatus] = useState<string | null>(season.defaultStatus)
    const [players, setPlayers] = useState<number>(0)

    const { t } = useTranslation();

    useEffect(() => {

    fetch(`https://api.mcstatus.io/v2/status/java/${season.ip}`)
        .then(response => response.json())
        .then(data => {

            if (data.online === true) {
                setStatus("Online")
                setPlayers(data.players.online)
            }

        })
        .catch(error => console.error('Error fetching data:', error));
    }, [season.ip]);

    const getStatusColor = (status: string) => {
        switch (status) {
            case "Online":
                return "limegreen";
            case "Offline":
                return "darkgrey";
            default:
                return "darkgrey";
        }
    };

    if (season.isShattered) {
        return (
            <ShatteredSeasonCardWrapper  color1={season.color1} color2={season.color2}>
                <ShatteredSeasonCardContainer>
                    <SeasonCardImageContainer>
                        <SeasonCardImage src={season.imageLabelUrl} alt={season.name}/>
                    </SeasonCardImageContainer>
                    <SeasonCardContent>
                        <SeasonCardInfo>
                            <StatusInfo>
                                <Status>Status: </Status>
                                <Status style={{color: "grey", marginTop : "10px"}}>Shattered</Status>
                            </StatusInfo>
                            <PlayerText>{t('unknownPlayers')}</PlayerText>
                        </SeasonCardInfo>
                    </SeasonCardContent>
                </ShatteredSeasonCardContainer>
            </ShatteredSeasonCardWrapper>
        )
    } else {
        return(
            <SeasonCardWrapper  color1={season.color1} color2={season.color2}>
                <SeasonCardContainer>
                    <SeasonCardImageContainer>
                        <SeasonCardImage src={season.imageLabelUrl} alt={season.name}/>
                    </SeasonCardImageContainer>
                    <SeasonCardContent>
                        <SeasonCardInfo>
                            {status != null &&
                                <StatusInfo>
                                    <Status>Status: </Status>
                                    <Status style={{color: getStatusColor(status), marginTop : "10px"}}>{status}</Status>
                                </StatusInfo>
                            }
                            <PlayerText>{t('players')} {players}</PlayerText>
                        </SeasonCardInfo>
                        {(season.blueMapLink && season.downloadMapUrl) !== null &&
                            <SeasonCardLinks>
                                {season.blueMapLink !== null &&
                                    status === "Online" ? (
                                        <Link href={season.blueMapLink}>
                                            <Button>
                                                BlueMap
                                            </Button>
                                        </Link>
                                    ) : (
                                        <ButtonOffline>
                                            BlueMap
                                        </ButtonOffline>
                                    )
                                }
                                {season.downloadMapUrl !== null &&
                                    <Link href={season.downloadMapUrl}>
                                        <Button>
                                            {t('download')}
                                        </Button>
                                    </Link>
                                }
                            </SeasonCardLinks>
                        }
                    </SeasonCardContent>
                </SeasonCardContainer>
            </SeasonCardWrapper>
        )
    }

}

export default SeasonCard;