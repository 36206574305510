import {
    EternityLogo,
    EternityLogoContainer, MainPageContainer,
    OuterSeasonCard,
    SeasonCardContainer, SeasonCardSpacer, OuterSignUpContainer
} from "./MainPage.styles";
import img_eternityCraft_main from "../../data/images/img_eternityCraft_main.png";
import SeasonCard from "../seasonCard/SeasonCard.component";
import {season1214, seasons} from "../../data/importantData";
import SignUp from "../signUp/SignUp.component";

const MainPage = () => {

    return (
        <MainPageContainer>
            <EternityLogoContainer className="animate__animated animate__fadeInDown">
                <EternityLogo src={img_eternityCraft_main} alt="Eternity Logo"/>
            </EternityLogoContainer>
            <SeasonCardContainer>
                <OuterSeasonCard className={"animate__animated animate__zoomIn animate__delay-1s"}>
                    <SeasonCard season={season1214}/>
                </OuterSeasonCard>
            </SeasonCardContainer>
            <SeasonCardSpacer className={"animate__animated animate__zoomIn animate__delay-2s"}/>
            <SeasonCardContainer>
                {seasons.map((season, index) => {
                    return (
                        <OuterSeasonCard key={index} className={"animate__animated animate__zoomIn animate__delay-2s"}>
                            <SeasonCard season={season}/>
                        </OuterSeasonCard>
                    );
                })}
            </SeasonCardContainer>
            <OuterSignUpContainer className={"animate__animated animate__fadeInRight animate__delay-3s"}>
                <SignUp/>
            </OuterSignUpContainer>
        </MainPageContainer>
    );

}

export default MainPage;