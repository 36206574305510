import styled from "styled-components";
import {mainTextColor, secondaryTextColor} from "../../variables.styles";

export const SignUpTableInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 95vh;
`;
export const SignUpTitle = styled.h1`
    font-size: 44px;
    color: ${mainTextColor};
    text-decoration: underline ${mainTextColor};
    display : flex;
    justify-content: center;
    cursor: default;
    margin-top: 50px;
`;

export const Loading = styled.span`
    font-size: 18px;
    color: ${secondaryTextColor};
    display : flex;
    justify-content: center;
    cursor: default;
    margin-top: 8px;
    font-style: italic;
`;